<template>
    <div>
        <h4 class="mb-1">{{ translate.translate("key", "nastroyki") }}</h4>
        <b-card>
            <div class="d-flex align-items-center justify-content-between">
                <h5>{{ translate.translate("key", "darkToggle") }}</h5>
                <DarkToggler class="d-block"/>
            </div>
        </b-card>
        <b-card>
            <div class="d-flex align-items-center justify-content-between">
                <h5>{{ translate.translate("key", "yazyk") }}</h5>
                <LanguageSelector class="d-block"/>
            </div>
        </b-card>
    </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler";
import LanguageSelector from "@/components/LanguageSelector";
import translate from "@/translation/translate";

export default {
    name: "Settings",
    components: {LanguageSelector, DarkToggler},
    data() {
        return {
            translate
        }
    }
}
</script>

<style scoped>

</style>